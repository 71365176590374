import React from "react";
import cx from "classnames";

import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import { MONTHS, URLS } from "../config";

import Image from "../components/image/image";
import ScrollSection from "../components/scroll-section/scroll-section";
import { DocumentToReactComponents } from "../components/blog-elements/blog-elements";
import TransitionLink from "../components/transition-link/transition-link";

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulBlog");
    const postTitle = post.title;
    const postSlug = this.props.pageContext.slug;
    const tripURL = `${URLS.Archive}${post.trip.slug}/`;
    const pageURL = `${tripURL}${postSlug}`;

    // const postCategories = post.category;
    // https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/
    // https://github.com/contentful/rich-text/blob/master/packages/rich-text-types/src/blocks.ts

    const date = `${MONTHS[new Date(post.publishDate).getMonth()]} ${new Date(
      post.publishDate
    ).getDate()}, ${new Date(post.publishDate).getFullYear()}`;

    return (
      <Layout
        classNames={{
          container: "bg-white",
        }}
        location={this.props.location}
        status={this.props.transitionStatus}
        showMenu={false}
      >
        <Seo type="blog" content={post} slug={pageURL} />
        <ScrollSection>
          <article>
            {post.heroImage ? (
              <div
                id="hero"
                className="blog-image flex align-end items-end h-screen object-cover w-full block relative z-10"
              >
                <Image
                  alt={post.title}
                  className={"!absolute top-0 left-0 h-screen w-full "}
                  gatsbyImageData={post.heroImage}
                />
                <div
                  className="blog-cover mx-auto w-full lg:max-w-6xl p-10 pb-16 inline-block bg-white relative text-center"
                >
                  <div className="blog-cover__wrapper">
                    <TransitionLink
                      to={`${URLS.Blog}${post.trip.slug}`}
                      className="has-hover-underline inline-block text-primary-red text-md leading-tight font-bold mb-6 hover:text-red-accessible"
                    >
                      {post.trip.title}
                    </TransitionLink>
                    <h1 className="text-5xl md:text-6xl leading-tight text-black font-bold mb-6">
                      {postTitle}
                    </h1>
                    <p className="mb-2 text-sm">
                      By {post.author.displayTitle || post.author.firstname}
                      <time
                        dateTime={post.publishDate}
                        className="inline-block ml-8"
                      >
                        {date}
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="blog-content bg-white pb-16 relative z-10">
              {post.heroImage && post.heroImage.description ? (
                <p className="absolute hidden 2xl:block top-0 right-0 z-20 text-xs text-gray-500 -mt-16 px-5.5 text-right w-2/12">
                  {post.heroImage?.description}
                </p>
              ) : (
                ""
              )}
              <div className={cx("blog-wrapper -mt-16 mx-5.5 relative z-10")}>
                <div className={cx("blog-content lg:max-w-5xl mx-auto")}>
                  {post.excerpt ? (
                    <div
                      className="font-display italic text-center text-xl leading-normal text-black align-center mt-8 mb-14"
                      dangerouslySetInnerHTML={{
                        __html: post.excerpt.childMarkdownRemark.html,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <div className="blog-components">
                    {post.content &&
                      DocumentToReactComponents(post.content, {})}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </ScrollSection>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const blogPostQuery = graphql`
  query BlogBySlug($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      seoTitle
      seoDescription
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 1920
            )
            description
          }
          ... on ContentfulComponent {
            contentful_id
            __typename
            displayTitle
            displayType
            type
            link
            location {
              lat
              lon
            }
            post {
              title
              slug
              minuteRead
              excerpt {
                excerpt
                childMarkdownRemark {
                  html
                }
              }
              country {
                continent {
                  slug
                  title
                  displayTitle
                }
                title
                slug
                displayTitle
              }
              author {
                surname
                firstname
                displayTitle
                slug
                picture {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    width: 424
                    height: 212
                  )
                }
              }
            }
            list {
              title
              text
              link
            }
            images {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 1920
              )
              title
            }
            title
            youtubeId
            subTitle
            link
            autoplay
            excerpt {
              excerpt
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      trip {
        slug
        title
      }
      excerpt {
        excerpt
        childMarkdownRemark {
          html
        }
      }
      minuteRead
      author {
        displayTitle
        firstname
        surname
        slug
        shortBio
        twitter
        location
        picture {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
      }
      publishDate
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
        description
      }
    }
  }
`;
